import {
  DeviceOrientationControls,
  OrbitControls,
  PerspectiveCamera,
  PointerLockControls,
} from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import { Scene } from "@/models/scene";
import { BugIcon, Edit, Edit2Icon, Edit3Icon, FlagIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import CameraRig from "./CameraRig";
import { ExperienceVirtualObject } from "@/models/experience-virtual-object";
import { useInteractionContext } from "./InteractionContext";
import { ViewerDynamicModelWrapper } from "./ViewerDynamicModelWrapper";
import { Experience } from "@/models/experience";
import { Set } from "@/models/set";
import { LazySetVirtualObjectMapping } from "@/components/sets/set-dictionary";

interface ViewerViewProps {
  set: Set;
  currentScene: Scene;
  experience: Experience;
  scenes: Scene[];
  experienceVirtualObjects: ExperienceVirtualObject[];
  currentCameraPosition: THREE.Vector3;
  currentTargetPosition: THREE.Vector3;
  setHasExperienceLoaded: (hasExperienceLoaded: boolean) => void;
  handleExperienceVirtualObjectTap: (obj: string) => void;
}

export const ViewerView = ({
  set,
  scenes,
  currentScene,
  experienceVirtualObjects,
  setHasExperienceLoaded,
  handleExperienceVirtualObjectTap,
}: ViewerViewProps) => {
  const { setCurrentlySelected } = useInteractionContext();

  const [VirtualObjectMapping, setVirtualObjectMapping] = useState<
    Record<string, JSX.Element>
  >({});

  useEffect(() => {
    LazySetVirtualObjectMapping(set.setComponentName).then((mapping) => {
      setVirtualObjectMapping(mapping.default);
    });
  }, [set]);

  return (
    <div
      style={{
        height: "100dvh",
        width: "100dvw",
        position: "relative",
      }}
    >
      <Canvas
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "black",
        }}
      >
        {/* NOTE: Camera components */}
        <CameraRig
          setHasExperienceLoaded={setHasExperienceLoaded}
          currentScene={currentScene}
        />

        {/* NOTE: Environment components */}
        <directionalLight intensity={2} />
        <ambientLight position={[10, 10, 10]} />

        {/* NOTE: Static models */}
        {experienceVirtualObjects &&
          experienceVirtualObjects
            .filter((e) => !e.virtualObjectId)
            .map((obj) => {
              return (
                <group key={obj.id}>
                  {VirtualObjectMapping ? VirtualObjectMapping[obj.id] : null}
                </group>
              );
            })}

        {/* NOTE: Dynamic models */}
        {scenes &&
          scenes.map(
            (scene) =>
              scene.objectPositions &&
              scene.objectPositions.map((position) =>
                position.selected
                  ? experienceVirtualObjects &&
                    experienceVirtualObjects
                      .filter((e) => e.positionId)
                      .map((obj) => {
                        if (obj.id === position.selected) {
                          return (
                            <ViewerDynamicModelWrapper
                              key={obj.id}
                              obj={obj}
                              currentSceneId={currentScene.id}
                              onClick={() => {
                                handleExperienceVirtualObjectTap(
                                  obj.virtualObjectId
                                );
                                setCurrentlySelected(obj.id);
                              }}
                            >
                              <group key={obj.id}>
                                {VirtualObjectMapping
                                  ? VirtualObjectMapping[obj.id]
                                  : null}
                              </group>
                            </ViewerDynamicModelWrapper>
                          );
                        }
                        return null;
                      })
                  : null
              )
          )}
      </Canvas>
    </div>
  );
};

interface EditorViewTabProps {
  isProductEditorOpen: boolean;
  setIsProductEditorOpen: (isProductEditorOpen: boolean) => void;
}

export const EditorViewTab = ({
  isProductEditorOpen,
  setIsProductEditorOpen,
}: EditorViewTabProps) => {
  return (
    <div
      onClick={() => setIsProductEditorOpen(!isProductEditorOpen)}
      className="text-white select-none self-center m-4 flex flex-row cursor-pointer"
    >
      <Edit className="mr-2" />
      <p>Edit Products / Assets</p>
    </div>
  );
};
