import {
  DocumentData,
  Query,
  QueryConstraint,
  Timestamp,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db, functionsUrl } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, map, of } from "rxjs";
import DataTransformationService from "./data-transformation.service";
import { ChatMessage } from "@/models/chat-message";
import axios from "axios";

function getOne(id: string): Observable<ChatMessage> {
  if (!id) return of(undefined);
  const docRef = doc(db, `chatMessages/${id}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

async function saveOne(
  brandId: string,
  experienceId: string,
  aiSessionId: string | null,
  message: string
): Promise<any> {
  try {
    if (!brandId || !experienceId || !message)
      throw new Error("Invalid chat message");

    //configure axios client with bearer token
    const token = await auth.currentUser.getIdToken();
    const client = axios.create({
      baseURL: `${functionsUrl}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    //get signed url from firebase function
    await client.post(`brand/${brandId}/chats/message`, {
      experienceId,
      aiSessionId,
      message,
    });

    return;
  } catch (err) {
    throw err;
  }
}

function getAllByUserAndExperience(
  tenantId: string,
  experienceId: string,
  userAccountId: string
): Observable<ChatMessage[]> {
  if (tenantId && userAccountId && experienceId) {
    const collectionRef = collection(db, "chatMessages");

    const queryConstraint: QueryConstraint[] = [
      where("tenantId", "==", tenantId),
      where("userAccountId", "==", userAccountId),
      where("experienceId", "==", experienceId),
      where("deleted", "==", false),
    ];

    const collectionQuery: Query<DocumentData> = query(
      collectionRef,
      ...queryConstraint
    );

    return collectionData(collectionQuery).pipe(
      map((collection) => {
        //sort by createdDate
        collection.sort(
          (a, b) =>
            (a.createdDate && a.createdDate instanceof Timestamp
              ? a.createdDate.seconds
              : 99999999999) -
            (b.createdDate && b.createdDate instanceof Timestamp
              ? b.createdDate.seconds
              : 99999999999)
        );

        //convert timestamps to Date
        collection = collection.map((firestoreDocument) =>
          DataTransformationService.convertTimestampsToIsoString(
            firestoreDocument
          )
        );
        return collection;
      })
    );
  } else {
    return of([]);
  }
}

const ChatMessageService = {
  getOne,
  saveOne,
  getAllByUserAndExperience,
};

export default ChatMessageService;
