import { Button } from "@/components/ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerPortal,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useExperienceContext } from "@/context/experience.context";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { HiOutlineX } from "react-icons/hi";
import ProductInformation from "./product-information";
import { Card, CardContent } from "../ui/card";
import { ExperienceVirtualObject } from "@/models/experience-virtual-object";
import { Badge } from "../ui/badge";
import { ScrollArea } from "../ui/scroll-area";
import {
  ChatBubble,
  ChatBubbleMessage,
} from "@/components/ui/chat/chat-bubble";
import { ChatInput } from "@/components/ui/chat/chat-input";

import { ChatMessageList } from "@/components/ui/chat/chat-message-list";
import { Send, StarsIcon, User2 } from "lucide-react";
import { Avatar, AvatarFallback } from "../ui/avatar";
import ChatMessageService from "@/services/chat-message.service copy";
import { combineLatest } from "rxjs";
import { useTenantContext } from "@/context/tenant.context";
import { useAuth } from "@/context/auth.context";
import { ChatMessage } from "@/models/chat-message";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

const formSchema = z.object({
  message: z.string().min(1, "Message is required"),
});

const MenuDrawer: FC<{
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
  // REFERENCE:
  const [snap, setSnap] = useState<number | string | null>(1);

  // STATE:
  const { authUser } = useAuth();
  const { experience, realExperienceVirtualObjects, listProductVariants } =
    useExperienceContext();
  const [
    savedListOfExperienceVirtualObjects,
    setSavedListOfExperienceVirtualObjects,
  ] = useState<ExperienceVirtualObject[]>([]);
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [aiSessionId, setAiSessionId] = useState<string | null>(null);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: "",
    },
  });

  useEffect(() => {
    const newListOfExperienceVirtualObjects: ExperienceVirtualObject[] = [];
    for (const listProductVariant of listProductVariants) {
      const matchingExperienceVirtualObjects =
        realExperienceVirtualObjects.find(
          (experienceVirtualObject) =>
            experienceVirtualObject.productId === listProductVariant.productId
        );
      if (matchingExperienceVirtualObjects) {
        newListOfExperienceVirtualObjects.push(
          matchingExperienceVirtualObjects
        );
      }
    }
    setSavedListOfExperienceVirtualObjects(newListOfExperienceVirtualObjects);
  }, [listProductVariants, realExperienceVirtualObjects]);

  useEffect(() => {
    if (experience && experience.isAiChatEnabled) {
      const subscription = combineLatest([
        ChatMessageService.getAllByUserAndExperience(
          experience.tenantId,
          experience.id,
          authUser.uid
        ),
      ]).subscribe({
        next: ([chatMessages]) => {
          setChatMessages(chatMessages);

          // get the session id of the last message
          const lastMessage = chatMessages[chatMessages.length - 1];
          if (lastMessage) setAiSessionId(lastMessage.aiSessionId);

          // scroll to the bottom
          scrollChatToBottom();
        },
        error: (err) => {
          alert(err.message);
          throw err;
        },
        complete: () => console.info("complete"),
      });

      return () => subscription.unsubscribe();
    }
  }, [experience]);

  const chatScrollAreaRef = useRef<HTMLDivElement>(null);

  const scrollChatToBottom = () => {
    if (chatScrollAreaRef.current)
      chatScrollAreaRef.current.scrollIntoView(false);
  };

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      await ChatMessageService.saveOne(
        experience.brandId,
        experience.id,
        aiSessionId,
        values.message
      );
      form.setValue("message", "");
    } catch (err) {
      alert(err.message);
      throw err;
    }
  }

  return (
    <Drawer
      open={isOpen}
      onOpenChange={setIsOpen}
      snapPoints={[1]}
      activeSnapPoint={snap}
      setActiveSnapPoint={setSnap}
    >
      <DrawerPortal>
        <DrawerContent className="fixed flex flex-col bg-white border border-gray-200 border-b-none rounded-t-[10px] bottom-0 left-0 right-0 h-full max-h-[95%] mx-[-1px]">
          <div className="absolute -top-3 right-3 z-10">
            <Button
              size="icon"
              onClick={() => setIsOpen(false)}
              variant="default"
            >
              <HiOutlineX className="w-6 h-6" />
            </Button>
          </div>
          <DrawerHeader className="hidden">
            <DrawerTitle className="hidden">Menu</DrawerTitle>
            <DrawerDescription className="hidden">
              Menu for the experience
            </DrawerDescription>
          </DrawerHeader>
          <Tabs defaultValue="products" className="w-full pl-2 pr-2">
            <TabsList className=" w-full">
              <TabsTrigger value="products">All Products</TabsTrigger>
              <TabsTrigger value="saved">
                Saved{" "}
                {savedListOfExperienceVirtualObjects.length > 0 && (
                  <Badge variant="default" className="ml-1">
                    {savedListOfExperienceVirtualObjects.length}
                  </Badge>
                )}
              </TabsTrigger>
              {experience.isAiChatEnabled && (
                <TabsTrigger value="chat" onClick={() => scrollChatToBottom()}>
                  Chat
                </TabsTrigger>
              )}
            </TabsList>
            <TabsContent value="chat">
              <ScrollArea className="h-screen">
                <ChatMessageList>
                  {/* Example messages */}
                  <ChatBubble layout="default" variant="received">
                    <Avatar>
                      <AvatarFallback>
                        <StarsIcon />
                      </AvatarFallback>
                    </Avatar>
                    <ChatBubbleMessage>
                      Hello, how can I help you?
                    </ChatBubbleMessage>
                  </ChatBubble>
                  {chatMessages.map((chatMessage, index) => (
                    <Fragment key={index}>
                      <ChatBubble layout="default" variant="sent">
                        <Avatar>
                          <AvatarFallback>
                            <User2 />
                          </AvatarFallback>
                        </Avatar>
                        <ChatBubbleMessage>
                          {chatMessage.request}
                        </ChatBubbleMessage>
                      </ChatBubble>
                      <ChatBubble layout="default" variant="received">
                        <Avatar>
                          <AvatarFallback>
                            <StarsIcon />
                          </AvatarFallback>
                        </Avatar>
                        <ChatBubbleMessage>
                          {chatMessage.response ? (
                            chatMessage.response.map(
                              (response, responseIndex) => (
                                <span key={responseIndex}>{response}</span>
                              )
                            )
                          ) : (
                            <span className="animate-bounce inline-block">
                              ...
                            </span>
                          )}
                        </ChatBubbleMessage>
                      </ChatBubble>
                    </Fragment>
                  ))}
                </ChatMessageList>
                <div className="h-72" ref={chatScrollAreaRef} />
              </ScrollArea>
              <div className="p-4 border-t border-gray-200 fixed bottom-0 left-0 right-0 bg-white">
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(onSubmit)}>
                    <FormField
                      control={form.control}
                      name="message"
                      render={({ field }) => (
                        <ChatInput
                          {...field}
                          placeholder="Type your question..."
                        />
                      )}
                    />
                    <Button
                      variant="default"
                      className="w-full mt-2"
                      size="icon"
                      type="submit"
                      disabled={form.formState.isSubmitting}
                    >
                      <Send className="w-6 h-6 mr-2" />
                      Send
                    </Button>
                  </form>
                </Form>
              </div>
            </TabsContent>
            <TabsContent value="saved">
              <ScrollArea className="h-screen">
                {savedListOfExperienceVirtualObjects.length === 0 && (
                  <p>No products are in your saved list.</p>
                )}
                {savedListOfExperienceVirtualObjects.map(
                  (experienceVirtualObject) => (
                    <Card key={experienceVirtualObject.id} className="p-2 mb-4">
                      <ProductInformation
                        experienceVirtualObject={experienceVirtualObject}
                        allowProductRemoval={true}
                      />
                    </Card>
                  )
                )}
                <div className="h-40" />
              </ScrollArea>
            </TabsContent>
            <TabsContent value="products">
              <ScrollArea className="h-screen">
                {realExperienceVirtualObjects.map((experienceVirtualObject) => (
                  <div key={experienceVirtualObject.id}>
                    {experienceVirtualObject.productId && (
                      <Card
                        key={experienceVirtualObject.id}
                        className="p-2 mb-4"
                      >
                        <ProductInformation
                          experienceVirtualObject={experienceVirtualObject}
                          allowProductRemoval={false}
                        />
                      </Card>
                    )}
                  </div>
                ))}
                <div className="h-40" />
              </ScrollArea>
            </TabsContent>
          </Tabs>
        </DrawerContent>
      </DrawerPortal>
    </Drawer>
  );
};

export default MenuDrawer;
